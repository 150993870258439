import React, {useState, useEffect, useRef} from 'react';
import { v4 as uuidv4 } from 'uuid';

export function Autocomplete({placeholder, onSuggestionSelected, buildUrl, transformSuggestionsResponse, displaySuggestion}) {

    const [searchInput, setSearchInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSuggestionsHidden, setIsSuggestionsHidden] = useState(true);
    const [optionIndex, setOptionIndex] = useState(-1);
    const [inputCleared, setInputCleared] = useState(false);
    const [suggestionSelected, setSuggestionSelected] = useState(false);

    const searchInputRef = useRef(null);
    const suggestionsRef = useRef([]);

    useEffect(() => {
        if (inputCleared) {
            searchInputRef.current.focus();
            setInputCleared(false)
        }
        if (!suggestionSelected) {
            const timeoutRef = setTimeout(() => {
                setLoading(true);
                const url = buildUrl(searchInput);
                fetch(url)
                    .then(res => res.json())
                    .then(json => {
                        setLoading(false);
                        setSuggestions(transformSuggestionsResponse(json));
                        setIsSuggestionsHidden(false);
                        setOptionIndex(-1);
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                        setSuggestions([]);
                        setIsSuggestionsHidden(true);
                        setOptionIndex(-1);
                    });
            }, 300)
            return () => clearTimeout(timeoutRef);
        }
    }, [searchInput]);

    const selectedSuggestion = (suggestion) => {
        setSuggestionSelected(true);
        setSearchInput(displaySuggestion(suggestion))
        setIsSuggestionsHidden(true);
        onSuggestionSelected(suggestion);
    }
    
    const resetHandler = () => {
        setInputCleared(true);
        setOptionIndex(-1);
        setSearchInput('');
        setSuggestions([]);
        setIsSuggestionsHidden(true);
    };
    
    const keyDownHandler = (event) => {
        switch (event.key) {
            case 'ArrowUp':
                let newOptionIndex = optionIndex <= 0 ? suggestions.length - 1 : optionIndex - 1;
                setOptionIndex(newOptionIndex);
                if (suggestionsRef.current[newOptionIndex]) {
                    suggestionsRef.current[newOptionIndex].scrollIntoView({behavior: 'auto', block: 'nearest'});
                    suggestionsRef.current[newOptionIndex].focus();
                }
                break;
            case 'ArrowDown':
                if (event.altKey) {
                    setIsSuggestionsHidden(false);
                } else {
                    setIsSuggestionsHidden(false);
                    let newOptionIndex = (isSuggestionsHidden || optionIndex === suggestions.length - 1) ? 0 : optionIndex + 1;
                    setOptionIndex(newOptionIndex);
                    if (suggestionsRef.current[newOptionIndex]) {
                        suggestionsRef.current[newOptionIndex].scrollIntoView({behavior: 'auto', block: 'nearest'});
                        suggestionsRef.current[newOptionIndex].focus();
                    }
                }
                break;
            case 'Escape':
                if (isSuggestionsHidden) setSearchInput('');
                searchInputRef.current.focus();
                setIsSuggestionsHidden(true);
                break;
            case 'Tab':
                setIsSuggestionsHidden(true);
                break;
            case 'Enter':
                if (suggestions[optionIndex]) selectedSuggestion(suggestions[optionIndex]);
                searchInputRef.current.focus();
                setIsSuggestionsHidden(true);
                break;
            default:
                break;
        }
    };

    return (
        <form className="maif-form" autoComplete="off" onSubmit={e => e.preventDefault()}>
            <div className="form-group">
                <div className="field-item champ-autocomplete">
                    <label className="sr-only" htmlFor="field-autocomplete-commune">Champ de recherche</label>
                    <input id="field-autocomplete-commune"
                           ref={searchInputRef}
                           name={uuidv4()}
                           type="text"
                           autoComplete="off"
                           aria-autocomplete="list"
                           role="combobox"
                           aria-owns="liste-options-field-autocomplete"
                           aria-describedby="field-autocomplete-commune-hint"
                           title={placeholder}
                           required="required"
                           disabled={suggestionSelected}
                           placeholder={placeholder}
                           value={searchInput}
                           onFocus={() => setIsSuggestionsHidden(false)}
                           onChange={(e) => setSearchInput(e.target.value)}
                           onKeyDown={keyDownHandler}/>
                    <p className="sr-only" id="field-autocomplete-commune-hint">À la sélection d'une adresse, vous serez redirigé vers la page correspondante des risques.</p>
                    {searchInput.length > 0 && loading &&
                        <div className="loader-wrap">
                            <div className="loader" title="Chargement des résultats en cours"></div>
                        </div>
                    }
                    <ul className={`dropdown-menu ${isSuggestionsHidden ? '' : 'show'}`}
                        id="liste-options-field-autocomplete"
                        role="listbox"
                        aria-label="Suggestions de saisie">
                        {suggestions.map((suggestion, index) => {
                            return (<li className="dropdown-item"
                                ref={(ref) => suggestionsRef.current[index] = ref}
                                role="option"
                                tabIndex="-1"
                                aria-selected={optionIndex === index}
                                id={"field-autocomplete-" + index}
                                onKeyDown={keyDownHandler}
                                onClick={() => selectedSuggestion(suggestion)}>
                                <span>{displaySuggestion(suggestion)}</span>
                            </li>);
                        })}
                    </ul>
                    <div className="sr-only" aria-live="polite" aria-atomic="true">
                        <p>{suggestions.length} résultats disponibles.</p>
                    </div>
                    {searchInput.length > 0 && !loading &&
                    <button className="reset"
                            type="button"
                            title="Effacer le contenu"
                            disabled={suggestionSelected}
                            onClick={resetHandler}>
                        <i className="maificon maificon-close" aria-hidden="true"></i>
                        <div className="sr-only">Effacer le contenu</div>
                    </button>
                    }
                </div>
            </div>
        </form>
    )
}